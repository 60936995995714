import { GetProps, styled } from '@tamagui/core';

import { Stack } from '../../layout';
import { InputFrameStyledContext } from '../InputStyledContext';

export const SelectDisplay = styled(Stack, {
  name: 'SelectDisplay',
  context: InputFrameStyledContext,

  flex: 1,

  variants: {
    multiple: {
      true: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '$2',
        py: '$2',
      },
    },
  } as const,
});

export type SelectDisplayProps = GetProps<typeof SelectDisplay>;
