import { useCallback } from 'react';

import { NotArray, SelectProps } from './SelectProps';
import { UseSelectItemsReturn } from './useSelectItems';

export type UseOnSelectArgs<T, Multiple extends boolean> = Pick<
  SelectProps<T, Multiple>,
  'multiple' | 'onChange' | 'value'
> & {
  items: UseSelectItemsReturn<T>;
  onOpenChange: (open: boolean) => void;
};

export const useOnSelect = <T, Multiple extends boolean = false>({
  items,
  onChange,
  onOpenChange,
  multiple = false as Multiple,
  value,
}: UseOnSelectArgs<T, Multiple>) => {
  return useCallback(
    (idx: number) => {
      if (multiple) {
        // TODO: Is there anyway that TS can actually type-narrow correctly here?
        const onChangeTyped = onChange as ((value: T[]) => void) | undefined;
        const valueTyped = value as T[] | null | undefined;

        if (valueTyped?.includes(items[idx].value)) {
          onChangeTyped?.([...valueTyped].filter((value) => value !== items[idx].value));
        } else {
          onChangeTyped?.([...(valueTyped ?? []), items[idx].value]);
        }
      } else {
        (onChange as ((value: NotArray<T> | null) => void) | undefined)?.(items[idx].value);
        onOpenChange(false);
      }
    },
    [items, multiple, onChange, onOpenChange, value],
  );
};
