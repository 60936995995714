import { GetProps, styled } from '@tamagui/core';

import { UtilityText } from '../../text';

export const FORM_HELPER_TEXT_NAME = 'InputHelperText';

export const FormHelperText = styled(UtilityText, {
  name: FORM_HELPER_TEXT_NAME,

  marginTop: '$2',
  token: 'utility.helper.small',
  color: '$onSurface.neutral.muted',
  pointerEvents: 'none',
});

export type FormHelperTextProps = GetProps<typeof FormHelperText>;
