import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function CheckmarkIconInteral({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...rest}>
      <Path d="M1.5 12.5l7 7 14-14" stroke={color} strokeWidth={1.5} />
    </Svg>
  );
}

export const CheckmarkIcon = memo<IconProps>(withIconTheme(CheckmarkIconInteral));
