import { ReactNode, useMemo } from 'react';

import { SelectItemProps } from './SelectItem';

export interface SelectedItem<T> {
  index: number;
  label: ReactNode;
  value: T;
}

export function useSelectedItems<T>(items: Array<SelectItemProps<T>>, value?: T | T[] | null) {
  return useMemo((): SelectedItem<T> | SelectedItem<T>[] | null => {
    if (Array.isArray(value)) {
      const selectedItems: SelectedItem<T>[] = [];

      items.forEach(({ label, value: optionValue }, index) => {
        if (value.includes(optionValue)) {
          selectedItems.push({ index, label, value: optionValue });
        }
      });

      return selectedItems;
    } else {
      const index = items.findIndex(({ value: optionValue }) => optionValue === value);
      return index >= 0 ? { index, label: items[index].label, value: items[index].value } : null;
    }
  }, [items, value]);
}
