import { GetProps, Stack, isWeb, styled } from '@tamagui/core';

import { InputFrameStyledContext } from './InputStyledContext';

const FORM_INPUT_FRAME_NAME = 'InputFrame';

export const InputFrame = styled(Stack, {
  name: FORM_INPUT_FRAME_NAME,
  context: InputFrameStyledContext,

  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  gap: '$1',

  borderWidth: 1,
  borderRadius: '$2',

  outlineWidth: 2,
  outlineColor: '$interactive.primary.rested',
  borderColor: '$onSurface.neutral.outlineAlt',
  backgroundColor: '$onSurface.neutral.defaultInverted',

  // this fixes a flex bug where it overflows container
  minWidth: 0,
  height: '$10',
  overflow: 'hidden',

  hoverStyle: {
    outlineColor: '$interactive.neutral.hovered',
    borderColor: '$interactive.neutral.hovered',
    cursor: 'text',
  },

  pressStyle: {
    outlineColor: '$interactive.neutral.hovered',
    borderColor: '$interactive.neutral.hovered',
    cursor: 'text',
  },

  variants: {
    variant: {
      ghost: {
        borderColor: '$transparent',
        backgroundColor: '$transparent',
      },
    },

    condensed: {
      true: {
        height: '$5',
      },
    },

    isInvalid: {
      true: {
        borderColor: '$onSurface.negative.outline',
        outlineColor: '$onSurface.negative.outline',

        hoverStyle: {
          borderColor: '$interactive.neutral.hovered',
          outlineColor: '$interactive.neutral.hovered',
        },

        pressStyle: {
          borderColor: '$interactive.neutral.hovered',
          outlineColor: '$interactive.neutral.hovered',
        },
      },
    },

    isError: {
      true: {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },

    isDisabled: {
      true: {
        backgroundColor: '$onSurface.neutral.zebraAlt',
        cursor: 'not-allowed',
        pointerEvents: 'none',
      },
    },

    isFocused: {
      true: {
        // This ensures that the outline stays there even if the actual element is not focused (even though isFocused is true)
        outlineStyle: 'solid',
        // borderColor: 'transparent',
        borderColor: isWeb ? 'transparent' : '$interactive.primary.rested',

        hoverStyle: {
          borderColor: 'transparent',
        },

        pressStyle: {
          borderColor: 'transparent',
        },
      },
    },

    multiple: {
      true: {
        h: 'unset',
        minHeight: '$10',
      },
    },
  } as const,
});

export type InputFrameProps = GetProps<typeof InputFrame>;
