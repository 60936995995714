import { ReactElement, ReactNode, Ref, forwardRef, useMemo, useState } from 'react';

import {
  GetProps,
  TamaguiElement,
  VariantSpreadFunction,
  createStyledContext,
  styled,
  useProps,
  withStaticProperties,
} from '@tamagui/core';

import { CheckmarkIcon } from '../../../icons';
import { Stack } from '../../layout';
import { UtilityText } from '../../text';

import { NotArray } from './SelectProps';

interface SelectItemStyledContextType {
  multiple?: boolean;
  selected?: boolean;
}

export const SelectItemStyledContext = createStyledContext<SelectItemStyledContextType>({
  multiple: false,
  selected: false,
});

export const SELECT_ITEM_CONTAINER_NAME = 'SelectItemContainer';
export const SELECT_ITEM_CHECKBOX_NAME = 'SelectItemCheckbox';
export const SELECT_ITEM_CHECKMARK_ICON_NAME = 'SelectItemCheckmarkIcon';
export const SELECT_ITEM_TEXT_NAME = 'SelectItemText';

export interface SelectItemExtraProps<T> {
  value: NotArray<T>;
  label: string | ReactNode;
}

const getContainerSelectedProps: VariantSpreadFunction<
  GetProps<typeof Stack> & SelectItemStyledContextType,
  boolean | undefined
> = (selected, { props: { multiple } }) => {
  let bg: string | undefined;

  if (selected) {
    if (!multiple) {
      bg = '$interactive.primary.focus';
    }
    return {
      bg,
      borderColor: 'transparent',
    };
  }
  return undefined;
};

const SelectItemContainer = styled(Stack, {
  name: SELECT_ITEM_CONTAINER_NAME,
  context: SelectItemStyledContext,

  px: '$4',
  py: '$3',
  borderWidth: '$0.5',
  borderColor: 'transparent',
  flexDirection: 'row',
  justifyContent: 'space-between',
  outlineWidth: 0,

  focusStyle: {
    borderColor: '$onSurface.primary.decorative',
  },

  pressStyle: {
    borderColor: 'transparent',
    bg: '$interactive.primary.hovered',
  },

  hoverStyle: {
    borderColor: 'transparent',
    bg: '$interactive.primary.hovered',
    cursor: 'pointer',
  },

  variants: {
    multiple: {
      true: {},
    },
    selected: getContainerSelectedProps,
  } as const,
});

const SelectItemCheckbox = styled(Stack, {
  name: SELECT_ITEM_CHECKBOX_NAME,
  context: SelectItemStyledContext,

  w: '$4',
  h: '$4',
  centered: true,

  borderWidth: '$0.25',
  borderRadius: '$0.5',

  variants: {
    selected: {
      true: {
        bg: '$onSurface.neutral.default',
      },
    },
  } as const,
});

const getTextSelectedProps: VariantSpreadFunction<
  GetProps<typeof UtilityText> & SelectItemStyledContextType,
  boolean | undefined
> = (selected, { props: { multiple } }) => {
  let color = '$onSurface.neutral.default';
  let token = 'utility.label.small.alt';

  if (selected) {
    if (multiple) {
      token = 'utility.label.small';
    } else {
      color = '$onSurface.neutral.defaultInverted';
    }
    return {
      color,
      token,
    };
  }
  return undefined;
};

export const SelectItemText = styled(UtilityText, {
  name: SELECT_ITEM_TEXT_NAME,
  context: SelectItemStyledContext,

  color: '$onSurface.neutral.default',
  token: 'utility.label.small.alt',

  variants: {
    multiple: { true: {} },
    selected: getTextSelectedProps,
  } as const,
});

export type SelectItemProps<T> = GetProps<typeof SelectItemContainer> & SelectItemExtraProps<T>;

const SelectItemComponent = forwardRef(<T,>(propsIn: SelectItemProps<T>, ref: Ref<TamaguiElement>) => {
  const { label, value, onMouseEnter, onMouseLeave, onPressIn, onPressOut, ...rest } = useProps(propsIn);

  const { multiple, selected } = SelectItemStyledContext.useStyledContext();

  const id = useMemo(() => `selectItem${value}`, [value]);

  const textProps = useMemo(
    () => ({
      [`$group-${id}-press`]: { color: '$onSurface.neutral.defaultInverted' },
      [`$group-${id}-hover`]: { color: '$onSurface.neutral.defaultInverted' },
    }),
    [id],
  );

  const checkboxProps = useMemo(
    () => ({
      [`$group-${id}-press`]: {
        bg: selected ? '$onSurface.neutral.defaultInverted' : undefined,
        borderColor: '$onSurface.neutral.defaultInverted',
      },
      [`$group-${id}-hover`]: {
        bg: selected ? '$onSurface.neutral.defaultInverted' : undefined,
        borderColor: '$onSurface.neutral.defaultInverted',
      },
    }),
    [id, selected],
  );

  // TODO: Use this once Tamagui fixes this for https://github.com/tamagui/tamagui/issues/1707
  // const checkIconProps = useMemo(
  //   () => ({
  //     [`$group-${id}-press`]: { color: '$onSurface.neutral.default' },
  //     [`$group-${id}-hover`]: { color: '$onSurface.neutral.default' },
  //   }),
  //   [id]
  // );

  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  return (
    <SelectItemContainer
      // @ts-expect-error group hasn't been added to the type of a Tamagui styled component
      group={id}
      ref={ref}
      onMouseEnter={(event) => {
        setIsHovered(true);
        onMouseEnter?.(event);
      }}
      onMouseLeave={(event) => {
        setIsHovered(false);
        onMouseLeave?.(event);
      }}
      onPressIn={(event) => {
        setIsPressed(true);
        onPressIn?.(event);
      }}
      onPressOut={(event) => {
        setIsPressed(false);
        onPressOut?.(event);
      }}
      {...rest}
    >
      {typeof label === 'string' ? <SelectItemText {...textProps}>{label}</SelectItemText> : label}
      {multiple && (
        <SelectItemCheckbox {...checkboxProps}>
          {selected && (
            <CheckmarkIcon
              size="xs"
              color={isHovered || isPressed ? '$onSurface.neutral.default' : '$onSurface.neutral.defaultInverted'}
            />
          )}
        </SelectItemCheckbox>
      )}
    </SelectItemContainer>
  );
});

const SelectItemStatics = { Text: SelectItemText };

export const SelectItem = withStaticProperties(
  /**
   * I'm not sure how to type this correctly, SelectItemContainer doesn't like the fact that SelectItemProps
   * has extra props from SelectItemExtraProps. Ideally we'd need to tell SelectItemContainer that it can take an
   * additional value/label. (Josh)
   */
  // @ts-ignore
  SelectItemContainer.styleable(SelectItemComponent),
  SelectItemStatics,
) as (<T>(props: SelectItemProps<T> & { ref?: Ref<TamaguiElement> }) => ReactElement) & typeof SelectItemStatics;
