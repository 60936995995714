import { KeyboardEvent, MutableRefObject } from 'react';
import { GestureResponderEvent } from 'react-native';

import { TamaguiElement } from '@tamagui/core';

import { CloseIcon } from '../../../icons';
import { Tag } from '../../chip';

import { SelectedItem } from './useSelectedItem';

export interface SelectedItemTagsProps<T> {
  onKeyDown?: (event: KeyboardEvent, item: SelectedItem<T>, selectedItemIndex: number) => void;
  onPress?: (event: GestureResponderEvent, item: SelectedItem<T>, selectedItemIndex: number) => void;
  selectedItems: SelectedItem<T>[];
  tagListRef?: MutableRefObject<Array<TamaguiElement | null>>;
}

export const SelectedItemTags = <T,>({ onKeyDown, onPress, selectedItems, tagListRef }: SelectedItemTagsProps<T>) => (
  <>
    {selectedItems.map((selectedItem, selectedIndex) => {
      const { label, value } = selectedItem;

      return (
        <Tag
          condensed
          key={String(value)}
          ref={(node) => {
            if (tagListRef) {
              tagListRef.current[selectedIndex] = node;
            }
          }}
          onKeyDown={(event) => onKeyDown?.(event, selectedItem, selectedIndex)}
          onPress={(event) => onPress?.(event, selectedItem, selectedIndex)}
          pressable
          variant="inverted"
        >
          <Tag.Label>{label}</Tag.Label>
          <Tag.Icon Icon={CloseIcon} />
        </Tag>
      );
    })}
  </>
);
