import { createStyledContext } from '@tamagui/core';

export interface InputFrameStyledContextType {
  isInvalid?: boolean;
  isError?: boolean;
  isFocused?: boolean;
  isDisabled?: boolean;
  multiple?: boolean;
  namespace?: string;
}

export const InputFrameStyledContext = createStyledContext<InputFrameStyledContextType>({
  isInvalid: false,
  isError: false,
  isFocused: false,
  isDisabled: false,
  multiple: false,
});
