import { ReactElement, Ref } from 'react';

import { TamaguiElement, withStaticProperties } from '@tamagui/core';

import { FormEndInputAdornment, FormStartInputAdornment } from '../adornment';

import { SelectComponent } from './SelectComponent';
import { SelectFrame } from './SelectFrame';
import { SelectItem } from './SelectItem';
import { SelectProps } from './SelectProps';

const SelectStatics = {
  End: FormEndInputAdornment,
  Error: SelectFrame.Error,
  Helper: SelectFrame.Helper,
  Item: SelectItem,
  Label: SelectFrame.Label,
  Start: FormStartInputAdornment,
};

export const Select = withStaticProperties(SelectComponent, SelectStatics) as (<T, Multiple extends boolean = false>(
  props: SelectProps<T, Multiple> & { ref?: Ref<TamaguiElement> },
) => ReactElement) &
  typeof SelectStatics;
