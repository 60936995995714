import { Children, ReactNode, useMemo } from 'react';

import { StaticConfig, isTamaguiElement } from '@tamagui/core';

import { FORM_END_INPUT_ADORNMENT_NAME, FORM_START_INPUT_ADORNMENT_NAME } from './adornment';
import { FORM_ERROR_CONTAINER_NAME } from './error';
import { FORM_HELPER_EXTRA_NAME, FORM_HELPER_TEXT_NAME } from './helper';
import { FORM_LABEL_NAME } from './label';

type TamaguiElement = React.ReactElement<unknown> & { type: { staticConfig: StaticConfig } };

export interface ComposedInputComponents {
  labelComponent?: TamaguiElement;
  errorComponent?: TamaguiElement;
  helperComponent?: TamaguiElement;
  helperExtraComponent?: TamaguiElement;
  startAdornments?: TamaguiElement[];
  endAdornments?: TamaguiElement[];
}

export interface ComposedInputReturn {
  formComponents: ComposedInputComponents;
  children?: ReactNode;
}

export const useComposedChildren = (children: ReactNode, override?: ComposedInputReturn): ComposedInputReturn => {
  return useMemo(() => {
    if (override) return override;
    const childrenArray = Children.toArray(children);
    const resultChildren: typeof childrenArray = [];

    const result: ComposedInputComponents = {
      labelComponent: undefined,
      errorComponent: undefined,
      helperComponent: undefined,
      helperExtraComponent: undefined,
      startAdornments: [],
      endAdornments: [],
    };

    childrenArray.forEach((child) => {
      if (isTamaguiElement(child, FORM_START_INPUT_ADORNMENT_NAME)) {
        result.startAdornments?.push(child);
        return;
      }

      if (isTamaguiElement(child, FORM_END_INPUT_ADORNMENT_NAME)) {
        result.endAdornments?.push(child);
        return;
      }

      if (isTamaguiElement(child, FORM_ERROR_CONTAINER_NAME)) {
        result.errorComponent = child;
        return;
      }

      if (isTamaguiElement(child, FORM_HELPER_TEXT_NAME)) {
        result.helperComponent = child;
        return;
      }

      if (isTamaguiElement(child, FORM_HELPER_EXTRA_NAME)) {
        result.helperExtraComponent = child;
        return;
      }

      if (isTamaguiElement(child, FORM_LABEL_NAME)) {
        result.labelComponent = child;
        return;
      }

      resultChildren.push(child);
    });

    return { formComponents: result, children: resultChildren };
  }, [children, override]);
};
