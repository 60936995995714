import { ReactElement, ReactNode, Ref, forwardRef, useEffect } from 'react';

import { TamaguiElement, withStaticProperties } from '@tamagui/core';

import { CaretDownIcon, WarningIcon } from '../../../icons';
import { Stack, StackProps } from '../../layout';
import { UtilityText } from '../../text';
import { FormEndInputAdornment } from '../adornment';
import { InputFrameStyledContext } from '../InputStyledContext';
import { ComposedInputComponents } from '../useComposedChildren';

import { SelectDisplay, SelectDisplayProps } from './SelectDisplay';
import { SelectFrameStatics } from './SelectFrame.statics';
import { SelectInputFrame, SelectInputFrameProps } from './SelectInputFrame';
import { SelectFrameStyledContext } from './SelectStyledContext';

export interface SelectFrameProps<T> {
  activeValue?: T;
  containerStackProps?: Omit<StackProps, 'onBlur' | 'onFocus' | 'onPress'>;
  content?: ReactNode;
  inputComponents?: ComposedInputComponents;
  inputFrameProps?: SelectInputFrameProps;
  placeholder?: ReactNode;
  selectDisplayProps?: SelectDisplayProps;
  wrapperStackProps?: Omit<StackProps, 'onBlur' | 'onFocus' | 'onPress'>;
}

/**
 * SelectFrame contains the platform agnostic components for the Select component. It must be wrapped by a SelectStyleContext.Provider component.
 */
export const SelectFrame = withStaticProperties(
  forwardRef(
    <T,>(
      {
        activeValue,
        containerStackProps,
        content,
        inputComponents,
        inputFrameProps,
        wrapperStackProps,
        selectDisplayProps,
        placeholder,
      }: SelectFrameProps<T>,
      ref: Ref<TamaguiElement>,
    ) => {
      const { endAdornments, errorComponent, helperComponent, labelComponent, startAdornments } = inputComponents || {};

      const { isDisabled, isError, isFocused, isInvalid, isOpen, multiple, namespace } =
        SelectFrameStyledContext.useStyledContext();

      useEffect(() => {
        if (!inputFrameProps?.['aria-label'] && !inputFrameProps?.['aria-labelledby'] && !labelComponent) {
          console.warn(
            'No label supplied for Select, this violates accessibility standards. If a text label cannot be supplied via Select.Label please provide one with the aria-label or aria-labelledby prop.',
          );
        }
      }, [inputFrameProps?.['aria-label'], inputFrameProps?.['aria-labelledby'], labelComponent]);

      /**
       * If we're using a `condensed` input frame, adjust the various padding and margin values.
       *
       * Applied manually vs contexts since there are multiple components to keep in sync.
       */
      const smallGap = inputFrameProps?.condensed ? '$1' : '$2';
      const largeGap = inputFrameProps?.condensed ? '$2' : '$4';
      const textToken = inputFrameProps?.condensed ? 'utility.helper.small' : 'utility.input';

      return (
        <Stack {...containerStackProps}>
          {labelComponent}
          {/* Style optimization so we can display the InputFrame "over" the error component */}
          <Stack w="100%" display="flex" flexDirection="column-reverse" {...wrapperStackProps}>
            {errorComponent}
            <InputFrameStyledContext.Provider
              isDisabled={isDisabled}
              isError={isError}
              // We want to trigger the focus style if either the element isFocused or it is open.
              isFocused={isFocused || isOpen}
              isInvalid={isInvalid}
              multiple={multiple}
              namespace={namespace}
            >
              <SelectInputFrame tabIndex="0" ref={ref} activeValue={activeValue} {...inputFrameProps}>
                {startAdornments}
                <SelectDisplay ml={startAdornments?.length ? smallGap : largeGap} {...selectDisplayProps}>
                  {typeof content === 'undefined' || typeof content === 'string' ? (
                    <UtilityText
                      token={textToken}
                      color={typeof content === 'string' ? undefined : '$onSurface.neutral.muted'}
                      ellipse
                    >
                      {content ?? placeholder}
                    </UtilityText>
                  ) : (
                    content
                  )}
                </SelectDisplay>
                {endAdornments}
                <FormEndInputAdornment
                  mr={isInvalid ? undefined : largeGap}
                  ml={endAdornments?.length ? undefined : largeGap}
                >
                  <CaretDownIcon color="$onSurface.neutral.default" />
                </FormEndInputAdornment>
                {isInvalid && (
                  <FormEndInputAdornment
                    mr={largeGap}
                    pl={smallGap}
                    borderLeftWidth="$0.25"
                    borderColor="$onSurface.neutral.zebraAlt"
                  >
                    <WarningIcon color="$onSurface.negative.outline" />
                  </FormEndInputAdornment>
                )}
              </SelectInputFrame>
            </InputFrameStyledContext.Provider>
          </Stack>
          {helperComponent}
        </Stack>
      );
    },
  ),
  SelectFrameStatics,
) as (<T>(props: SelectFrameProps<T> & { ref?: Ref<TamaguiElement> }) => ReactElement) & typeof SelectFrameStatics;
