import { GetProps, styled } from '@tamagui/core';

import { Stack } from '../../layout';

export const FORM_HELPER_EXTRA_NAME = 'FormHelperExtra';

export const FormHelperExtra = styled(Stack, {
  name: FORM_HELPER_EXTRA_NAME,
});

// @ts-ignore
export type FormHelperExtraProps = GetProps<typeof FormHelperExtra>;
