import { styled } from '@tamagui/core';

import { Stack } from '../../layout';

export const FORM_START_INPUT_ADORNMENT_NAME = 'FormStartInputAdornment';
export const FORM_END_INPUT_ADORNMENT_NAME = 'FormEndInputAdornment';

const FormInputAdornment = styled(Stack, {
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '$4',
  minHeight: '$4',
  zIndex: 1,
});

export const FormStartInputAdornment = styled(FormInputAdornment, {
  name: FORM_START_INPUT_ADORNMENT_NAME,
});

export const FormEndInputAdornment = styled(FormInputAdornment, {
  name: FORM_END_INPUT_ADORNMENT_NAME,
});
