import { GetProps, styled } from '@tamagui/core';

import { UtilityText } from '../../text';

export const FORM_LABEL_NAME = 'FormLabel';

export const FormLabel = styled(UtilityText, {
  name: FORM_LABEL_NAME,
  tag: 'label',

  token: 'utility.label.xsmall',
  pointerEvents: 'none',
  pb: '$3',
});

export type FormLabelProps = GetProps<typeof FormLabel>;
