import {
  ForwardedRef,
  Fragment,
  MutableRefObject,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';

import { TamaguiElement, useComposedRefs } from '@tamagui/core';

import {
  FloatingFocusManager,
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
} from '@floating-ui/react';

import { size as bricksSize } from '../../../tokens/size';
import { Divider } from '../../divider';
import { Stack } from '../../layout';
import { useComposedChildren } from '../useComposedChildren';

import { SelectedItemTags } from './SelectedItemTags';
import { SelectFrame } from './SelectFrame';
import { SelectItem, SelectItemStyledContext } from './SelectItem';
import { SelectProps } from './SelectProps';
import { SelectFrameStyledContext } from './SelectStyledContext';
import { useOnSelect } from './useOnSelect';
import { useSelectedItems } from './useSelectedItem';
import { useSelectItems } from './useSelectItems';

export const SelectComponent = forwardRef(
  <T, Multiple extends boolean = false>(
    {
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      children,
      disabled,
      invalid,
      multiple = false as Multiple,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      value,
      returnFocus = true,
      inputFrameProps,
      ...rest
    }: SelectProps<T, Multiple>,
    ref: ForwardedRef<TamaguiElement>,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const { formComponents } = useComposedChildren(children);

    const isError = useMemo(() => !!formComponents.errorComponent, [formComponents.errorComponent]);
    const isInvalid = useMemo(() => !!(isError || invalid), [isError, invalid]);

    const onOpenChange = useCallback(
      (open: boolean) => {
        setIsOpen(open && !disabled);
      },
      [disabled],
    );

    useEffect(() => {
      if (disabled) {
        onOpenChange(false);
      }
    }, [disabled]);

    const { context, floatingStyles, refs } = useFloating({
      placement: 'bottom-start',
      open: isOpen,
      onOpenChange,
      whileElementsMounted: autoUpdate,
      middleware: [
        offset(bricksSize['1']),
        flip(),
        size({
          apply({ rects, elements, availableHeight, availableWidth }) {
            Object.assign(elements.floating.style, {
              maxHeight: `${availableHeight - bricksSize['1']}px`,
              maxWidth: `${availableWidth}px`,
              minWidth: `${rects.reference.width}px`,
            });
          },
        }),
      ],
    });

    const inputRef = useRef<HTMLElement>(null);

    const composedRefs = useComposedRefs<HTMLElement>(refs.setReference, ref as Ref<HTMLElement>, inputRef);

    const items = useSelectItems<T>(children);
    const selectedItems = useSelectedItems<T>(items, value);
    const activeItem = useMemo(() => (activeIndex != null ? items[activeIndex] : undefined), [activeIndex, items]);

    const listRef = useRef<Array<TamaguiElement | null>>([]);

    const selectedIndex = useMemo(() => {
      if (Array.isArray(selectedItems)) {
        return selectedItems[0]?.index;
      } else {
        return selectedItems?.index;
      }
    }, [selectedItems]);

    const listNavigation = useListNavigation(context, {
      listRef: listRef as MutableRefObject<Array<HTMLElement | null>>,
      activeIndex,
      selectedIndex,
      onNavigate: setActiveIndex,
      loop: true,
    });
    // We want to use click instead of mousedown here because click will actually give the input focus which is helpful for triggering
    // validations that might be tied to the onBlur event.
    const click = useClick(context, { event: 'click' });
    const dismiss = useDismiss(context);

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([click, dismiss, listNavigation]);

    const onSelect = useOnSelect({ items, onChange, onOpenChange, multiple, value });

    const tagListRef = useRef<Array<TamaguiElement | null>>([]);

    const content = useMemo(() => {
      if (Array.isArray(selectedItems)) {
        return selectedItems.length ? (
          <SelectedItemTags
            onKeyDown={(event, { index }, selectedIndex) => {
              if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                event.stopPropagation();

                if (selectedIndex > 0) {
                  tagListRef.current[selectedIndex - 1]?.focus();
                } else if (selectedItems.length > 1) {
                  tagListRef.current[1]?.focus();
                } else {
                  inputRef.current?.focus();
                }
                onSelect(index);
              }
            }}
            onPress={(event, { index }) => {
              event.preventDefault();
              event.stopPropagation();
              onSelect(index);
            }}
            selectedItems={selectedItems}
            tagListRef={tagListRef}
          />
        ) : undefined;
      } else {
        return selectedItems?.label;
      }
    }, [onSelect, selectedItems]);

    const namespace = useId();

    return (
      <SelectFrameStyledContext.Provider
        isDisabled={disabled}
        isFocused={isFocused}
        isInvalid={isInvalid}
        isOpen={isOpen}
        isError={isError}
        multiple={multiple}
        namespace={namespace}
      >
        <SelectFrame
          activeValue={activeItem ? activeItem.value : undefined}
          containerStackProps={rest}
          inputComponents={formComponents}
          inputFrameProps={{
            ...getReferenceProps({
              onBlur: (event) => {
                setIsFocused(false);
                // This is somewhat of a "quick" fix for now, we don't want to trigger onBlur when the listbox is open so that we don't
                // trigger validations that might happen onBlur. However, this doesn't handle the case if focus is moved to the listbox
                // and then removed from either component, in which we'd also ideally trigger onBlur and subsequenty validations.
                if (!isOpen) {
                  onBlur?.(event);
                }
              },
              onFocus: (event) => {
                setIsFocused(true);
                onFocus?.(event);
              },
            }),
            'aria-label': ariaLabel,
            'aria-labelledby': ariaLabelledBy,
            ...inputFrameProps,
          }}
          content={content}
          placeholder={placeholder}
          ref={composedRefs}
        />
        {isOpen && (
          <FloatingPortal>
            <FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss returnFocus={returnFocus}>
              <Stack
                ref={refs.setFloating}
                backgroundColor="$onSurface.neutral.defaultInverted"
                borderColor="$onSurface.neutral.outlineAlt"
                borderWidth="$0.25"
                borderRadius="$1"
                tabIndex={-1}
                aria-multiselectable={multiple}
                zIndex="$popover"
                // Not recognizing listbox as a valid role attribute here...
                // @ts-ignore
                role="listbox"
                elevation="$6"
                id={`${namespace}_dropdown`}
                style={{
                  overflowY: 'auto',
                  outline: 'none',
                  ...floatingStyles,
                }}
                {...getFloatingProps()}
              >
                {items.map(({ index: _, value: itemValue, ...rest }, index) => (
                  <Fragment key={String(itemValue)}>
                    <SelectItemStyledContext.Provider
                      multiple={multiple}
                      selected={Array.isArray(value) ? value.includes(itemValue) : value === itemValue}
                    >
                      <SelectItem
                        tabIndex={index === activeIndex ? 0 : -1}
                        id={`${namespace}_element_${itemValue}`}
                        ref={(node) => {
                          listRef.current[index] = node;
                        }}
                        aria-selected={Array.isArray(value) ? value.includes(itemValue) : value === itemValue}
                        role="option"
                        value={itemValue}
                        {...rest}
                        {...getItemProps({
                          onClick: () => onSelect(index),
                          onKeyDown: (event) => {
                            if (event.key === 'Enter' || event.key === ' ') {
                              event.preventDefault();
                              onSelect(index);
                            }
                          },
                        })}
                      />
                    </SelectItemStyledContext.Provider>
                    {index !== items.length - 1 && <Divider alt solid />}
                  </Fragment>
                ))}
              </Stack>
            </FloatingFocusManager>
          </FloatingPortal>
        )}
      </SelectFrameStyledContext.Provider>
    );
  },
);
