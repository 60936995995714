import { Children, ReactNode, isValidElement, useMemo } from 'react';

import { isTamaguiElement } from '@tamagui/core';

import { SELECT_ITEM_CONTAINER_NAME, SelectItemProps } from './SelectItem';
import { NotArray } from './SelectProps';

export type UseSelectItemsReturn<T> = Array<SelectItemProps<NotArray<T>> & { index: number }>;

/**
 * Provided the children of a Select component, this function pulls out all of the nested child nodes that are a SelectItem component and pushes them into an array of triples
 * in the format of [value, label, component] so that they can be used for rendering other parts of the Select component correctly.
 */
export function useSelectItems<T>(children: ReactNode) {
  return useMemo(() => {
    const items: UseSelectItemsReturn<T> = [];
    let index = 0;

    const unwrap = (children: ReactNode) => {
      Children.forEach(children, (child) => {
        if (isValidElement(child)) {
          if (isTamaguiElement(child) && child.type?.staticConfig?.componentName === SELECT_ITEM_CONTAINER_NAME) {
            items.push({ index: index++, ...(child.props as SelectItemProps<NotArray<T>>) });
          } else if (child.props?.children) {
            unwrap(child.props?.children);
          }
        }
      });
    };

    unwrap(children);
    return items;
  }, [children]);
}
